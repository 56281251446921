import React, { useEffect, useState, useContext } from 'react'
import { SyncOutlined } from "@ant-design/icons";
import TopDash from '../components/MainDash/TopDash';
import './dashboard.scss'
import axios from 'axios';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Bar, Line } from 'react-chartjs-2';
import moment from 'moment'
import ChartDataLabels from 'chartjs-plugin-datalabels';
import '../components/Table/Table2.scss'
import '../components/MainDash/MainDash.scss'
import {
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    TimeScale,
    Title,
    Filler,
    BarElement
} from 'chart.js';
import { UserContext } from '../context';
import 'chartjs-adapter-moment';
import 'jspdf-autotable';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

ChartJS.register(ChartDataLabels);
ChartJS.register(ArcElement, Tooltip, Legend);

ChartJS.register(
    LineElement,
    PointElement,
    LinearScale,
    TimeScale,
    Title,
    Tooltip,
    Legend,
    BarElement,
    CategoryScale
  );
export default function Dashboard() {
    const [isLoading, setIsLoading] = useState(false);
    const [state, setState] = useContext(UserContext);
    const [startDate, setStartDate] = useState(null);
    const [orientatoriOptions, setOrientatoriOptions] = useState([]);
    const [selectedOrientatore, setSelectedOrientatore] = useState(state.user.role && state.user.role === "orientatore" ? state.user._id : null);
    const [endDate, setEndDate] = useState(null);
    const [activeTab, setActiveTab] = useState("lead");
    const userFixId = state.user.role && state.user.role === "orientatore" ? state.user.utente : state.user._id; // "655f707143a59f06d5d4dc3b";
    const [numberLeads, setNumberLeads] = useState()
    const [originaNumberLeads, setOriginalNumberLeads] = useState()
    const [rimuoviFiltri, setRimuoviFiltri] = useState(false)
    const [startMonth, setStartMonth] = useState(null);
    const [endMonth, setEndMonth] = useState(null)

    const [chartData, setChartData] = useState();
    const [chartDataCV, setChartDataCV] = useState();
    const [chartDataSales, setChartDataSales] = useState();
    const [chartDataVal, setChartDataVal] = useState();

    const [chartDataMonth, setChartDataMonth] = useState();
    const [chartDataCVMonth, setChartDataCVMonth] = useState();
    const [chartDataSalesMonth, setChartDataSalesMonth] = useState();
    const [chartDataValMonth, setChartDataValMonth] = useState();

    const [isLoading1, setIsLoading1] = useState(false); 
    const [isLoading2, setIsLoading2] = useState(false);
    const [isLoading3, setIsLoading3] = useState(false);
    const [isLoading4, setIsLoading4] = useState(false);
    const [isLoading5, setIsLoading5] = useState(false);
    const [isLoading6, setIsLoading6] = useState(false);
    const [isLoading7, setIsLoading7] = useState(false);
    const [isLoading8, setIsLoading8] = useState(false);

    const handleApplyFilter = () => {
        setRimuoviFiltri(true)
        fetchLeadsNumber()
    }
    console.log(startDate, endDate)
    const handleApplyFilterGrafic = () => {
      setIsLoading1(true); setIsLoading2(true); setIsLoading3(true); setIsLoading4(true); setIsLoading5(true); setIsLoading6(true); setIsLoading7(true); setIsLoading8(true)
      
      setRimuoviFiltri(true)
      fetchWeeklyData(startMonth, endMonth, selectedOrientatore)
      fetchWeeklyConversion(startMonth, endMonth, selectedOrientatore)
      fetchWeeklySales(startMonth, endMonth, selectedOrientatore)
      fetchWeeklyValuation(startMonth, endMonth, selectedOrientatore)
      fetchMonthlyData(startMonth, endMonth, selectedOrientatore)
      fetchMonthlyConversion(startMonth, endMonth, selectedOrientatore)
      fetchMonthlySales(startMonth, endMonth, selectedOrientatore)
      fetchMonthlyValuation(startMonth, endMonth, selectedOrientatore)
    }
    const handleRemoveFilter = () => {
        setRimuoviFiltri(false)
        setSelectedOrientatore()
        setStartDate(null)
        setEndDate(null)
        setSelectedOrientatore("")
        setNumberLeads(originaNumberLeads)
        fetchLeadsNumber(true)
    }

    useEffect(() => {
      const getOrientatori = async () => {
        await axios.get(`/utenti/${userFixId}/orientatori`)
          .then(response => {
            const data = response.data.orientatori;
            setOrientatoriOptions(data);
          })
          .catch(error => {
            console.error(error);
          });
      }
  
      if (state && state.token && (!state.user.role || state.user.role !== "orientatore")) {
        getOrientatori()
      };
    }, [])

    const handleRemoveFilterGrafic = () => {
      setIsLoading1(true); setIsLoading2(true); setIsLoading3(true); setIsLoading4(true); setIsLoading5(true); setIsLoading6(true); setIsLoading7(true); setIsLoading8(true)

      setRimuoviFiltri(false)
      setStartMonth(null)
      setSelectedOrientatore("")
      setEndMonth(null)
      if (state.user.role && state.user.role === "orientatore") {
        fetchWeeklyData(undefined, undefined, selectedOrientatore)
        fetchWeeklyConversion(undefined, undefined, selectedOrientatore)
        fetchWeeklySales(undefined, undefined, selectedOrientatore)
        fetchWeeklyValuation(undefined, undefined, selectedOrientatore)
        fetchMonthlyData(undefined, undefined, selectedOrientatore)
        fetchMonthlyConversion(undefined, undefined, selectedOrientatore)
        fetchMonthlySales(undefined, undefined, selectedOrientatore)
        fetchMonthlyValuation(undefined, undefined, selectedOrientatore)
      } else {
        fetchWeeklyData()
        fetchWeeklyConversion()
        fetchWeeklySales()
        fetchWeeklyValuation()
        fetchMonthlyData()
        fetchMonthlyConversion()
        fetchMonthlySales()
        fetchMonthlyValuation()        
      }
    }
    const fetchLeadsNumber = async (primo) => {
      if (!primo) setIsLoading(true)
        try {
            const requestData = {
                _id: userFixId,
              };
      
              if (startDate && endDate && !primo) {
                const startDateUTC = startDate ? moment(startDate).utc().startOf('day').add(1, 'days').toDate() : null;
                const endDateUTC = endDate ? moment(endDate).utc().endOf('day').add(2, 'days').startOf('day').subtract(1, 'millisecond').toDate() : null;
                requestData.startDate = startDateUTC;
                requestData.endDate = endDateUTC;
              }

              if (selectedOrientatore && selectedOrientatore?.trim() !== ""){
                requestData.orientatore = selectedOrientatore
              }

            const response = await axios.post('/get-dashboard-number', requestData);

            console.log(response.data)
            setNumberLeads(response.data);
            if (primo) setOriginalNumberLeads(response.data);
            setIsLoading(false)
        } catch (error) {
            console.error(error.message);
        }
    };

    useEffect(() => {
        setIsLoading(true)
        fetchLeadsNumber(true)
    }, []);

    const blucolor = "#3471CC"
        const fetchWeeklyData = async (startMonth, endMonth, orientatore) => {
          try {
            const response = await axios.post('/get-weekly-leads', { _id: userFixId, startMonth, endMonth, orientatore });
            const data = response.data;

            const weeks = data.map(item => moment(item.week).format('D MMM YYYY'));
            const leads = data.map(item => item.leadCount);
    
            setChartData({
              labels: weeks,
              datasets: [
                {
                  label: 'Lead',
                  data: leads,
                  fill: false,
                  backgroundColor: blucolor,
                  borderColor: blucolor,
                  tension: 0.5,
                },
              ],
            });
            setIsLoading1(false);
          } catch (error) {
            console.error(error.message);
          }
        };

        const fetchWeeklyConversion = async (startMonth, endMonth, orientatore) => {
            try {
              const response = await axios.post('/get-weekly-conversion', { _id: userFixId, startMonth, endMonth, orientatore });
              const data = response.data;
      
              const weeks = data.map(item => moment(item.week).format('D MMM YYYY'));
              const conversions = data.map(item => item.conversionRate);
      
              setChartDataCV({
                labels: weeks,
                datasets: [
                  {
                    label: '% vendute',
                    data: conversions,
                    fill: false,
                    backgroundColor: blucolor,
                    borderColor: blucolor,
                    tension: 0.4, // Questa opzione rende la linea curva
                  },
                ],
              });
              setIsLoading2(false);
            } catch (error) {
              console.error(error.message);
            }
          };

          const fetchWeeklySales = async (startMonth, endMonth, orientatore) => {
            try {
              const response = await axios.post('/get-weekly-sales', { _id: userFixId, startMonth, endMonth, orientatore });
              const data = response.data;
              console.log(data)
              const weeks = data?.map(item => moment(item.week).format('D MMM YYYY'));
              const sales = data?.map(item => item.salesCount);

              setChartDataSales({
                labels: weeks,
                datasets: [
                  {
                    label: 'Vendite',
                    data: sales,
                    backgroundColor: blucolor,
                    borderColor: blucolor,
                  },
                ],
              });
              setIsLoading3(false);
            } catch (error) {
              console.error(error.message);
            }
          };

          const fetchWeeklyValuation = async (startMonth, endMonth, orientatore) => {
            try {
              const response = await axios.post('/get-weekly-valuation', { _id: userFixId, startMonth, endMonth, orientatore });
              const data = response.data;
      
              const weeks = data.map(item => moment(item.week).format('D MMM YYYY'));
              const evaluations = data.map(item => item.evaluationRate);
      
              setChartDataVal({
                labels: weeks,
                datasets: [
                  {
                    label: '% Lead in Valutazione',
                    data: evaluations,
                    fill: false,
                    backgroundColor: blucolor,
                    borderColor: blucolor,
                    tension: 0.4,
                  },
                ],
              });
              setIsLoading4(false);
            } catch (error) {
              console.error(error.message);
            }
          };

          const fetchMonthlyData = async (startMonth, endMonth, orientatore) => {
            try {
              const response = await axios.post('/get-monthly-leads', { _id: userFixId, startMonth, endMonth, orientatore });
              const data = response.data;
              
              const months = data.map(item => moment(item.month, 'YYYY-MM').format('MMM YYYY'));
              const leads = data.map(item => item.leadCount);
          
              setChartDataMonth({
                labels: months,
                datasets: [
                  {
                    label: 'Lead',
                    data: leads,
                    fill: false,
                    backgroundColor: blucolor,
                    borderColor: blucolor,
                    tension: 0.5,
                  },
                ],
              });
              setIsLoading5(false);
            } catch (error) {
              console.error(error.message);
            }
          };

          const fetchMonthlyConversion = async (startMonth, endMonth, orientatore) => {
            try {
              const response = await axios.post('/get-monthly-conversion', { _id: userFixId, startMonth, endMonth, orientatore });
              const data = response.data;
      
              const months = data.map(item => moment(item.month, 'YYYY-MM').format('MMM YYYY'));
              const conversions = data.map(item => item.conversionRate);
      
              setChartDataCVMonth({
                labels: months,
                datasets: [
                  {
                    label: '% vendute',
                    data: conversions,
                    fill: false,
                    backgroundColor: blucolor,
                    borderColor: blucolor,
                    tension: 0.4, // Questa opzione rende la linea curva
                  },
                ],
              });
              setIsLoading6(false);
            } catch (error) {
              console.error(error.message);
            }
          };

          const fetchMonthlySales = async (startMonth, endMonth, orientatore) => {
            try {
              const response = await axios.post('/get-monthly-sales', { _id: userFixId, startMonth, endMonth, orientatore });
              const data = response.data;

              const months = data.map(item => moment(item.month, 'YYYY-MM').format('MMM YYYY'));
              const sales = data.map(item => item.salesCount);

              setChartDataSalesMonth({
                labels: months,
                datasets: [
                  {
                    label: 'Vendite',
                    data: sales,
                    backgroundColor: blucolor,
                    borderColor: blucolor,
                  },
                ],
              });
              setIsLoading7(false);
            } catch (error) {
              console.error(error.message);
            }
          };

          const fetchMonthlyValuation = async (startMonth, endMonth, orientatore) => {
            try {
              const response = await axios.post('/get-monthly-valuation', { _id: userFixId, startMonth, endMonth, orientatore });
              const data = response.data;
      
              const months = data.map(item => moment(item.month, 'YYYY-MM').format('MMM YYYY'));
              const evaluation = data.map(item => item.evaluationRate);
      
              setChartDataValMonth({
                labels: months,
                datasets: [
                  {
                    label: '% Lead in Valutazione',
                    data: evaluation,
                    fill: false,
                    backgroundColor: blucolor,
                    borderColor: blucolor,
                    tension: 0.4,
                  },
                ],
              });
              setIsLoading8(false);
            } catch (error) {
              console.error(error.message);
            }
          };

    useEffect(() => {
      setIsLoading1(true); setIsLoading2(true); setIsLoading3(true); setIsLoading4(true); setIsLoading5(true); setIsLoading6(true); setIsLoading7(true); setIsLoading8(true)
        if (state.user.role && state.user.role === "orientatore"){
          fetchWeeklyData(undefined, undefined, selectedOrientatore)
          fetchWeeklyConversion(undefined, undefined, selectedOrientatore)
          fetchWeeklySales(undefined, undefined, selectedOrientatore)
          fetchWeeklyValuation(undefined, undefined, selectedOrientatore)
          fetchMonthlyData(undefined, undefined, selectedOrientatore)
          fetchMonthlyConversion(undefined, undefined, selectedOrientatore)
          fetchMonthlySales(undefined, undefined, selectedOrientatore)
          fetchMonthlyValuation(undefined, undefined, selectedOrientatore)
        } else {
          fetchWeeklyData()
          fetchWeeklyConversion()
          fetchWeeklySales()
          fetchWeeklyValuation()
          fetchMonthlyData()
          fetchMonthlyConversion()
          fetchMonthlySales()
          fetchMonthlyValuation()          
        }
      }, [userFixId]);

      const calculatePercentage = (value, total) => {
        if (total === 0) {
          return '0%';
        }
        if (value === 0 || !value){
          return '0%';
        }
        const percentage = (value / total * 100).toFixed(1);
        return `${percentage}%`;
      }; 
      
      const handleFocus = (e) => {
        e.target.blur(); // Rimuovi il focus per evitare il comportamento di evidenziazione del testo
        e.target.focus(); // Riapplica il focus per aprire il selettore di date
      };

    return (
        <div>
            {isLoading ?
                <div
                    className="d-flex justify-content-center fw-bold"
                    style={{ height: "90vh" }}
                >
                    <div className="d-flex align-items-center">
                        <SyncOutlined spin style={{ fontSize: "50px" }} />
                    </div>
                </div>
                :
                <div>
                    <div className='tab-dashboard-top'>
                        <div className='tab-dashboard'>
                            <button onClick={() => setActiveTab('lead')} className={activeTab === "lead" ? "active-button" : ''}>Lead totali</button>
                            <button onClick={() => setActiveTab('settimanale')} className={activeTab === "settimanale" ? "active-button" : ''}>Trend settimanale</button>
                            <button onClick={() => setActiveTab('mensile')} className={activeTab === "mensile" ? "active-button" : ''}>Trend mensile</button>
                        </div>
                        <div className='filter-dashboard'>
                            {activeTab !== "settimanale" && activeTab !== "mensile" ?
                            <div>
                                <p style={{ color: "gray", fontSize: '14px' }}>Filtra per data</p>
                                <div className="filter-data">
                                <div>
                                    <label>Da</label>
                                    <DatePicker
                                        selected={startDate}
                                        onChange={(date) => setStartDate(date)}
                                        dateFormat="dd-MM-yyyy"
                                        placeholderText="Seleziona da"
                                        customInput={
                                          <input
                                            type="text"
                                            value={startDate ? moment.utc(startDate).format('YYYY-MM-DD') : ''}
                                            readOnly
                                            className='input-mese'
                                          />
                                        }
                                      />
                                </div>
                                <div>
                                    <label>A</label>
                                    <DatePicker
                                        selected={endDate}
                                        onChange={(date) => setEndDate(date)}
                                        dateFormat="dd-MM-yyyy"
                                        placeholderText="Seleziona a"
                                        customInput={
                                          <input
                                            type="text"
                                            value={endDate ? moment.utc(endDate).format('YYYY-MM-DD') : ''}
                                            readOnly
                                            className='input-mese'
                                          />
                                        }
                                      />
                                </div>
                                </div>            
                            </div> : 
                            <div>
                                <p style={{ color: "gray", fontSize: '14px' }}>Filtra per mese</p>
                                <div className="filter-data">
                                  <div>
                                    <label>Da</label>
                                    <DatePicker
                                      selected={startMonth}
                                      onChange={(date) => setStartMonth(date)}
                                      dateFormat="MM-yyyy"
                                      showMonthYearPicker
                                      placeholderText="Seleziona da"
                                      customInput={
                                        <input
                                          type="text"
                                          value={startMonth ? moment(startMonth).format('YYYY-MM') : ''}
                                          readOnly
                                          className='input-mese'
                                        />
                                      }
                                    />
                                  </div>
                                  <div>
                                    <label>A</label>
                                    <DatePicker
                                      selected={endMonth}
                                      onChange={(date) => setEndMonth(date)}
                                      dateFormat="MM-yyyy"
                                      showMonthYearPicker
                                      placeholderText="Seleziona a"
                                      customInput={
                                        <input
                                          type="text"
                                          value={endMonth ? moment(endMonth).format('YYYY-MM') : ''}
                                          readOnly
                                          className='input-mese'
                                        />
                                      }
                                    />
                                  </div>
                                </div>
                              </div>}

                            {(!state.user.role || state.user.role !== "orientatore") && 
                            <div className="filter-etichette">
                                <p style={{ color: "gray", fontSize: '14px' }}>Filtra per orientatore</p>
                                <select
                                    id="etichettaSelect"
                                    placeholder="Nome orientatore"
                                    value={selectedOrientatore}
                                    onChange={(e) => {setSelectedOrientatore(e.target.value)}}
                                >
                                    <option value="">Tutti</option>
                                    {orientatoriOptions && orientatoriOptions.map((option) => (
                                    <option key={option._id} value={option._id}>
                                    {option.nome} {' '} {option.cognome}
                                    </option>
                                ))}
                                </select>
                            </div>}
                            {!rimuoviFiltri ? <button onClick={activeTab !== "settimanale" && activeTab !== "mensile" ? handleApplyFilter : handleApplyFilterGrafic}>Applica filtri</button>
                             : 
                            <button onClick={activeTab !== "settimanale" && activeTab !== "mensile" ? handleRemoveFilter : handleRemoveFilterGrafic}>Rimuovi</button>}
                        </div>
                    </div>
                    {/*<TopDash hideexport hideattivi hidecerca generatePdf={generatePDF} />*/}
                    <div className='dashbody'>
                        {activeTab === "lead" ? (
                        <div>
                            <div className='lead-box total-lead-box'>
                                <h3>Numero Lead</h3>
                                <p>{numberLeads?.totalLeads ? numberLeads?.totalLeads : '0'}</p>
                                <div className='lead-line' style={{ backgroundColor: "#3471CC" }}></div>
                            </div>
                            <div className='lead-container'>
                                {[
                                { title: "Da contattare", value: numberLeads?.["Da contattare"], color: "#92B5D1" },
                                { title: "In lavorazione", value: numberLeads?.['In lavorazione'], color: "#416386" },
                                { title: "Non risponde", value: numberLeads?.["Non risponde"], color: "#F78500" },
                                { title: "Irraggiungibile", value: numberLeads?.['Irraggiungibile'], color: "#C11246" },
                                { title: "Non valido", value: numberLeads?.['Non valido'], color: "#263F44" },
                                { title: "Lead persa", value: numberLeads?.['Non interessato'], color: "#842F1F" }, 
                                { title: "Opportunità", value: numberLeads?.['Opportunità'], color: "#FABB05" },
                                { title: "In valutazione", value: numberLeads?.['In valutazione'], color: "#3470CB" },
                                { title: "Venduto", value: numberLeads?.['Venduto'], color: "#2F958A" },
                                { title: "Iscr. posticipata", value: numberLeads?.['Iscrizione posticipata'], color: "#00EDB4" },
                                ].map((lead, index) => (
                                <div className='lead-box' key={index}>
                                    <h3>{lead.title}</h3>
                                    <p>{lead.value ? lead.value : '0'}</p>
                                    <div className='lead-line' style={{ backgroundColor: lead.color }}></div>
                                    <span className='lead-average' style={{ backgroundColor: lead.color }}>{calculatePercentage(lead.value, numberLeads?.totalLeads)}</span>
                                </div>
                                ))}
                            </div>
                        </div>
                        ) : activeTab === "settimanale" ? (
                        <div>
                            <div className='line-chart-container'>
                                <h4>Andamento settimanale lead (escludendo in “Non Validi”)</h4>
                                {chartData && !isLoading1 ? 
                                <Line
                                data={chartData} 
                                options={{
                                    maintainAspectRatio: false,
                                    scales: {
                                      y: {
                                        beginAtZero: true,
                                      },
                                    },
                                    elements: {
                                      line: {
                                        tension: 1, // Rende la linea curva
                                      },
                                    },
                                    plugins: {
                                        datalabels: {
                                            display: true,
                                            backgroundColor: function(context) {
                                                return "#3471CC";
                                              },
                                              borderRadius: 4,
                                              color: 'white',
                                              formatter: Math.round,
                                              padding: 6
                                          },
                                    }
                                  }}
                                 /> :
                                  <div className="loader-container">
                                    <SyncOutlined spin style={{ fontSize: "50px" }} />
                                  </div>}
                            </div>
                            <div className='line-chart-container'>
                                <h4>Conversion per settimana creazione Lead (escludendo in “Non Validi”)</h4>
                                {chartDataCV && !isLoading2 ?
                                <Line
                                data={chartDataCV} 
                                options={{
                                    maintainAspectRatio: false,
                                    scales: {
                                        y: {
                                            beginAtZero: true,
                                            //max: 50,
                                            callback: function(value) {
                                              return value + '%'; // Aggiungi il simbolo percentuale alle etichette
                                            }
                                          },
                                        },
                                    elements: {
                                      line: {
                                        tension: 1, // Rende la linea curva
                                      },
                                    },
                                    plugins: {
                                        tooltip: {
                                          callbacks: {
                                            label: function(context) {
                                              return context.raw + '%';
                                            }
                                          }
                                        },
                                        datalabels: {
                                            display: true,
                                            backgroundColor: function(context) {
                                                return "#3471CC";
                                              },
                                              formatter: function(value, context) {
                                                return value + '%'; // Mostra il valore con il simbolo percentuale
                                              },
                                              borderRadius: 4,
                                              color: 'white',
                                              padding: 6
                                          },
                                      },
                                  }}
                                  height={400}
                                 />:
                                 <div className="loader-container">
                                 <SyncOutlined spin style={{ fontSize: "50px" }} />
                               </div>}
                            </div>
                            <div className='line-chart-container'>
                                <h4>Iscrizioni per settimana creazione lead</h4>
                                {chartDataSales && !isLoading3 ?
                                <Bar
                                    data={chartDataSales}
                                    options={{
                                    maintainAspectRatio: false,
                                    scales: {
                                        y: {
                                        beginAtZero: true,
                                        max: 14,
                                        },
                                    },
                                    plugins: {
                                      datalabels: {
                                        display: true,
                                        backgroundColor: function(context) {
                                            return "#3471CC";
                                          },
                                          formatter: function(value, context) {
                                            return value; // Mostra il valore con il simbolo percentuale
                                          },
                                          borderRadius: 3,
                                          color: 'white',
                                          padding: 6
                                      },
                                    }
                                    }}
                                    height={400}
                                />: 
                                <div className="loader-container">
                                <SyncOutlined spin style={{ fontSize: "50px" }} />
                              </div>}
                            </div>
                            <div className='line-chart-container'>
                                <h4>% Lead in valutazione (escludendo in “Non Validi”)</h4>
                                {chartDataVal && !isLoading4 ?
                                <Line
                                data={chartDataVal}
                                options={{
                                    maintainAspectRatio: false,
                                    scales: {
                                        y: {
                                            beginAtZero: true,
                                            //max: 50,
                                            callback: function(value) {
                                              return value + '%';
                                            }
                                          },
                                        },
                                    elements: {
                                      line: {
                                        tension: 1, // Rende la linea curva
                                      },
                                    },
                                    plugins: {
                                        tooltip: {
                                          callbacks: {
                                            label: function(context) {
                                              return context.raw + '%';
                                            }
                                          }
                                        },
                                        datalabels: {
                                            display: true,
                                            backgroundColor: function(context) {
                                                return "#3471CC";
                                              },
                                              formatter: function(value, context) {
                                                return value + '%';
                                              },
                                              borderRadius: 4,
                                              color: 'white',
                                              padding: 6
                                          },
                                      },
                                  }}
                                  height={400}
                                 />: 
                                 <div className="loader-container">
                                 <SyncOutlined spin style={{ fontSize: "50px" }} />
                               </div>}
                            </div>
                        </div>
                        ) : (
                        <div>
                          <div className='line-chart-container'>
                              <h4>Andamento Mensile lead (escludendo in “Non Validi”)</h4>
                              {chartDataMonth && !isLoading5 ?
                              <Line 
                              data={chartDataMonth} 
                              options={{
                                  maintainAspectRatio: false,
                                  scales: {
                                    y: {
                                      beginAtZero: true,
                                    },
                                  },
                                  elements: {
                                    line: {
                                      tension: 1, // Rende la linea curva
                                    },
                                  },
                                  plugins: {
                                      datalabels: {
                                          display: true,
                                          backgroundColor: function(context) {
                                              return "#3471CC";
                                            },
                                            borderRadius: 4,
                                            color: 'white',
                                            formatter: Math.round,
                                            padding: 6
                                        },
                                  }
                                }}
                               /> : 
                               <div className="loader-container">
                               <SyncOutlined spin style={{ fontSize: "50px" }} />
                             </div>}
                          </div>
                          <div className='line-chart-container'>
                              <h4>Conversion per mese creazione Lead (escludendo in “Non Validi”)</h4>
                              {chartDataCVMonth && !isLoading6 ?
                              <Line
                              data={chartDataCVMonth} 
                              options={{
                                  maintainAspectRatio: false,
                                  scales: {
                                      y: {
                                          beginAtZero: true,
                                          //max: 50,
                                          callback: function(value) {
                                            return value + '%'; // Aggiungi il simbolo percentuale alle etichette
                                          }
                                        },
                                      },
                                  elements: {
                                    line: {
                                      tension: 1, // Rende la linea curva
                                    },
                                  },
                                  plugins: {
                                      tooltip: {
                                        callbacks: {
                                          label: function(context) {
                                            return context.raw + '%';
                                          }
                                        }
                                      },
                                      datalabels: {
                                          display: true,
                                          backgroundColor: function(context) {
                                              return "#3471CC";
                                            },
                                            formatter: function(value, context) {
                                              return value + '%'; // Mostra il valore con il simbolo percentuale
                                            },
                                            borderRadius: 4,
                                            color: 'white',
                                            padding: 6
                                        },
                                    },
                                }}
                                height={400}
                               /> : 
                               <div className="loader-container">
                               <SyncOutlined spin style={{ fontSize: "50px" }} />
                             </div>}
                          </div>
                          <div className='line-chart-container'>
                              <h4>Iscrizioni per mese creazione lead</h4>
                              {chartDataSalesMonth && !isLoading7 ?
                              <Bar
                                  data={chartDataSalesMonth}
                                  options={{
                                  maintainAspectRatio: false,
                                  scales: {
                                      y: {
                                      beginAtZero: true,
                                      max: 40,
                                      },
                                  },
                                  plugins: {
                                    datalabels: {
                                      display: true,
                                      backgroundColor: function(context) {
                                          return "#3471CC";
                                        },
                                        formatter: function(value, context) {
                                          return value; // Mostra il valore con il simbolo percentuale
                                        },
                                        borderRadius: 3,
                                        color: 'white',
                                        padding: 6
                                    },
                                  }
                                  }}
                                  height={400}
                              /> :
                              <div className="loader-container">
                              <SyncOutlined spin style={{ fontSize: "50px" }} />
                            </div>}
                          </div>
                          <div className='line-chart-container'>
                              <h4>% Lead in valutazione (escludendo in “Non Validi”)</h4>
                              {chartDataValMonth && !isLoading8 ?
                              <Line
                              data={chartDataValMonth}
                              options={{
                                  maintainAspectRatio: false,
                                  scales: {
                                      y: {
                                          beginAtZero: true,
                                          //max: 50,
                                          callback: function(value) {
                                            return value + '%';
                                          }
                                        },
                                      },
                                  elements: {
                                    line: {
                                      tension: 1, // Rende la linea curva
                                    },
                                  },
                                  plugins: {
                                      tooltip: {
                                        callbacks: {
                                          label: function(context) {
                                            return context.raw + '%';
                                          }
                                        }
                                      },
                                      datalabels: {
                                          display: true,
                                          backgroundColor: function(context) {
                                              return "#3471CC";
                                            },
                                            formatter: function(value, context) {
                                              return value + '%';
                                            },
                                            borderRadius: 4,
                                            color: 'white',
                                            padding: 6
                                        },
                                    },
                                }}
                                height={400}
                               /> : 
                               <div className="loader-container">
                               <SyncOutlined spin style={{ fontSize: "50px" }} />
                             </div>}
                          </div>
                      </div>
                        )}
                    </div>
                </div>
            }
        </div>
    )
}
