import React, { useContext, useEffect, useState, useRef } from 'react';
import './calendar.css';
import { SidebarContext } from '../context/SidebarContext';
import axios from 'axios';
import { UserContext } from '../context';
import moment from 'moment';
import 'moment/locale/it';
import { Calendar, formatDate } from '@fullcalendar/core'
import interactionPlugin from '@fullcalendar/interaction'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import itLocale from '@fullcalendar/core/locales/it';
import { SearchOutlined } from '@ant-design/icons';
import toast from 'react-hot-toast';
import PopupModifyCalendar from '../components/Table/popupModify/PopupModifyCalendar';

function MyCalendar({leads, setSelectedLead, setOpenInfoCal, setPopupPosition, saveNewRecall, setOpenDeleteRecall}) {
  const calendarRef = useRef(null);
  console.log(leads);

  const initialView = localStorage.getItem('calendarioVisualizzazione') || 'timeGridWeek';
  const formatDateString = (inputDate) => {
    let parsedDate;
    console.log(inputDate);
  
    // Prova a parsare con il formato DD-MM-YY HH:mm
    if (moment(inputDate, 'DD-MM-YYYY HH:mm', true).isValid()) {
      parsedDate = moment(inputDate, 'DD-MM-YYYY HH:mm');
    } 
    // Prova a parsare con il formato YY-MM-DD HH:mm
    else if (moment(inputDate, 'YY-MM-DD HH:mm', true).isValid()) {
      parsedDate = moment(inputDate, 'YY-MM-DD HH:mm');
    } 
    else {
      // Prova a parsare senza validazione
      parsedDate = moment(inputDate, ['DD-MM-YYYY HH:mm', 'YY-MM-DD HH:mm']);
    }
  
    /*if (!parsedDate.isValid()) {
      throw new Error('Formato data non valido');
    }*/
  
    const formattedDate = parsedDate.format('DD/MM/YYYY HH:mm');
    return formattedDate;
  };
  useEffect(() => {
    const calendarEl = calendarRef.current;

    const calendar = new Calendar(calendarEl, {
      plugins:[dayGridPlugin, timeGridPlugin, interactionPlugin],
      headerToolbar:{
        left: 'prev,next today',
        center: 'title',
        right: 'dayGridMonth,timeGridWeek,timeGridDay'
      },
      initialView: initialView,
      editable: true,
      locale: itLocale,
      slotMinTime: '06:00:00', 
      slotMaxTime: '24:00:00',
      events: leads,
      eventContent: function (arg, createElement) {
        var titleText = arg.event.title;
        var descriptionText = (arg.event.extendedProps.provenienza === "AI chatbot" || (arg.event.extendedProps.appDate && arg.event.extendedProps.appDate.trim() !== "")) && !arg.event.extendedProps.doppio ? formatDateString(arg.event.extendedProps.appDate) : arg.event.extendedProps.recallHours; 
        var iniziali = arg.event.extendedProps.iniziali;
        console.log(arg.event.extendedProps.prequalificaSpostato)
        return createElement(
          'div',
          {
            class: arg.event.extendedProps.prequalificaSpostato ? "event-content-container prequalifica-calendar" : (arg.event.extendedProps.provenienza === "AI chatbot" || (arg.event.extendedProps.appDate && arg.event.extendedProps.appDate.trim() !== "")) && !arg.event.extendedProps.doppio ? 'event-content-container chatbot-calendar' : 'event-content-container',
          },

          //createElement('span', {class: 'iniziali-icon-calendar'}, iniziali),
          createElement('span', { class: 'event-title' }, titleText),
          createElement('span', { class: 'event-description' }, descriptionText),
          createElement(
            'span',
            {
              class: 'close-icon-calendar',
              onclick: function () {
                setOpenDeleteRecall(true);
                setSelectedLead(arg.event.extendedProps);
                setOpenInfoCal(false);
              },
            },
            'X'
          ),
          );
      },
      eventClick: function(info) {
        setOpenInfoCal(true);
        document.body.classList.add("overlay");
        setSelectedLead(info.event._def.extendedProps);
        const eventRect = info.el.getBoundingClientRect();
        const popupWidth = 400;
        const popupHeight = 300;
        const top = eventRect.top + window.scrollY - popupHeight + 100; // -10 per un piccolo offset
        const left = eventRect.left + window.scrollX - popupWidth / 2 + eventRect.width / 2 -400;

        setPopupPosition({ top, left });
      },
      eventDrop: function (info) {
        // Accedi alla nuova data di inizio dell'evento
        const newStartDate = info.event.start;
      
        // Utilizza moment.js per formattare la data e l'orario nel formato desiderato
        const formattedDate = moment(newStartDate).format('YYYY-MM-DDTHH:mm:ss.SSSZ');
        const formattedTime = moment(newStartDate).format('HH:mm');
      
        // Ecco i valori formattati che puoi inviare al tuo database
        console.log('Data formattata:', formattedDate);
        console.log('Orario formattato:', formattedTime);
      
        saveNewRecall(info.event.id, formattedDate, formattedTime);
      },
      viewDidMount: function (info) {
        localStorage.setItem('calendarioVisualizzazione', info.view.type);
      },
    });

    calendar.render();

    return () => {
      calendar.destroy();
    };
  }, [leads]);

  return <div className='my-calendar' ref={calendarRef}></div>;
}

const CalendarM = () => {
    const { isSidebarOpen } = useContext(SidebarContext);
    const [state] = useContext(UserContext);
    const [orientatoriOptions, setOrientatoriOptions] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [originalData, setOriginalData] = useState([]);

    const containerStyle = {
        transition: 'width 0.3s ease',
    }

    const formatDate = (date) => {
        return moment(date).format('LL'); 
    };
    const formatDateString = (inputDate) => {
      let parsedDate;
      console.log(inputDate);
    
      // Prova a parsare con il formato DD-MM-YY HH:mm
      if (moment(inputDate, 'DD-MM-YYYY HH:mm', true).isValid()) {
        parsedDate = moment(inputDate, 'DD-MM-YYYY HH:mm');
      } 
      // Prova a parsare con il formato YY-MM-DD HH:mm
      else if (moment(inputDate, 'YY-MM-DD HH:mm', true).isValid()) {
        parsedDate = moment(inputDate, 'YY-MM-DD HH:mm');
      }
      else {
        // Prova a parsare senza validazione
        parsedDate = moment(inputDate, ['DD-MM-YYYY HH:mm', 'YY-MM-DD HH:mm']);
      }
    
      if (!parsedDate.isValid()) {
        throw new Error('Formato data non valido');
      }
    
      const formattedDate = parsedDate.format('DD/MM/YYYY HH:mm');
      const data = moment(formattedDate, 'DD/MM/YYYY HH:mm').toDate()
      return data;
    };
    
    const fetchLeads = async (orin) => {

        try {
          const response = await axios.post('/get-lead-calendar', {
            _id: state.user._id,
            role: state.user.role && state.user.role === "orientatore" ? "orientatore" : "utente",
            //_id: "655f707143a59f06d5d4dc3b"
          });
    
          const doppioAppuntamento = response.data.filter(lead => (lead.appDate && lead.appDate !== "") && lead.recallDate);
          //console.log(doppioAppuntamento)
          const filteredDoppione = doppioAppuntamento.map((lead) => {
            const recallDateValid = lead.recallDate ? moment(lead.recallDate).isValid() : false;
            const recallHoursValid = lead.recallHours ? moment(lead.recallHours, 'HH:mm').isValid() : false;
            const appDateValid = lead.appDate ? moment(lead.appDate).isValid() : false;
      
            if (!appDateValid && (!recallDateValid || !recallHoursValid)) {
              console.log('Lead con data non valida:', lead);
            }
            const telephone = lead.numeroTelefono ? lead.numeroTelefono.toString() : '';
            const cleanedTelephone =
              telephone.startsWith('+39') && telephone.length === 13
                ? telephone.substring(3)
                : telephone;
    
          const dateTime = moment(`${lead.recallDate} ${lead.recallHours}`, 'YYYY-MM-DD HH:mm:ss').toDate()
          const inizialiNome = lead.orientatori ? lead.orientatori.nome.charAt(0).toUpperCase() : '';
          const inizialiCognome = lead.orientatori ? lead.orientatori.cognome.charAt(0).toUpperCase() : '';

            return {
              id: lead._id,
              title: lead.nome + ' ' + lead.cognome,
              extendedProps : {
              iniziali: inizialiNome + inizialiCognome,  
              name: lead.nome,
              surname: lead.cognome,
              doppio: true,
              email: lead.email,
              date: lead.data,
              telephone: cleanedTelephone,
              status: lead.esito,
              orientatore: lead.orientatori ? lead.orientatori.nome + ' ' + lead.orientatori.cognome : '',
              facolta: lead.facolta ? lead.facolta : '',
              fatturato: lead.fatturato ? lead.fatturato : '',
              università: lead.università ? lead.università : '',
              provenienza: lead.campagna,
              prequalificaSpostato: lead.prequalificaSpostato ? lead.prequalificaSpostato : false,
              campagna: lead.utmCampaign ? lead.utmCampaign : "",
              corsoDiLaurea: lead.corsoDiLaurea ? lead.corsoDiLaurea : '',
              oreStudio: lead.oreStudio ? lead.oreStudio : '',
              provincia: lead.provincia ? lead.provincia : '',
              note: lead.note ? lead.note : '',
              id: lead._id,
              frequentiUni: lead.frequentiUni ? lead.frequentiUni : null,
              lavoro: lead.lavoro ? lead.lavoro : null,
              oraChiamataRichiesto: lead.oraChiamataRichiesto ? lead.oraChiamataRichiesto : "",
              etichette: lead.etichette ? lead.etichette : null,
              motivo: lead.motivo ? lead.motivo : null,
              recallHours: lead.recallHours ? lead.recallHours : null,
              recallDate: lead.recallDate ? lead.recallDate : null,
              lastModify: lead.lastModify ? lead.lastModify : null, 
              tipologiaCorso: lead.tipologiaCorso ? lead.tipologiaCorso : "",
              budget: lead.budget ? lead.budget : "",
              enrollmentTime: lead.enrollmentTime ? lead.enrollmentTime : "",
              oreStudio: lead.oreStudio ? lead.oreStudio : "",
              categories: lead.categories ? lead.categories : "",
              leadAmbassador: lead.leadAmbassador ? lead.leadAmbassador : undefined,
              summary: lead.summary ? lead.summary : "",
              appDate: lead.appDate ? lead.appDate : "",
              priorità: lead.priorità ? lead.priorità : null,
              utmContent: lead.utmContent ? lead.utmContent : ""
            },
              start: dateTime,
              description: `${lead.recallHours}`,
            };
          });
          const filteredTableLead = response.data.map((lead) => {
            const recallDateValid = lead.recallDate ? moment(lead.recallDate).isValid() : false;
            const recallHoursValid = lead.recallHours ? moment(lead.recallHours, 'HH:mm').isValid() : false;
            const appDateValid = lead.appDate ? moment(lead.appDate).isValid() : false;
      
            if (!appDateValid && (!recallDateValid || !recallHoursValid)) {
              console.log('Lead con data non valida:', lead);
            }
            const telephone = lead.numeroTelefono ? lead.numeroTelefono.toString() : '';
            const cleanedTelephone =
              telephone.startsWith('+39') && telephone.length === 13
                ? telephone.substring(3)
                : telephone;
          
          const dateTime = ((lead.campagna === "AI chatbot" && lead.appDate?.trim() !== "") || (lead.appDate && lead.appDate?.trim()  !== '')) ?
          formatDateString(lead.appDate) :
          moment(`${lead.recallDate} ${lead.recallHours}`, 'YYYY-MM-DD HH:mm:ss').toDate();
          const inizialiNome = lead.orientatori ? lead.orientatori.nome.charAt(0).toUpperCase() : '';
          const inizialiCognome = lead.orientatori ? lead.orientatori.cognome.charAt(0).toUpperCase() : '';

            return {
              id: lead._id,
              title: lead.nome + ' ' + lead.cognome,
              extendedProps : {
              iniziali: inizialiNome + inizialiCognome,  
              name: lead.nome,
              surname: lead.cognome,
              email: lead.email,
              date: lead.data,
              telephone: cleanedTelephone,
              status: lead.esito,
              doppio: false,
              prequalificaSpostato: lead.prequalificaSpostato ? lead.prequalificaSpostato : false,
              orientatore: lead.orientatori ? lead.orientatori.nome + ' ' + lead.orientatori.cognome : '',
              facolta: lead.facolta ? lead.facolta : '',
              fatturato: lead.fatturato ? lead.fatturato : '',
              università: lead.università ? lead.università : '',
              corsoDiLaurea: lead.corsoDiLaurea ? lead.corsoDiLaurea : '',
              oreStudio: lead.oreStudio ? lead.oreStudio : '',
              provincia: lead.provincia ? lead.provincia : '',
              note: lead.note ? lead.note : '',
              id: lead._id,
              frequentiUni: lead.frequentiUni ? lead.frequentiUni : null,
              lavoro: lead.lavoro ? lead.lavoro : null,
              oraChiamataRichiesto: lead.oraChiamataRichiesto ? lead.oraChiamataRichiesto : "",
              etichette: lead.etichette ? lead.etichette : null,
              motivo: lead.motivo ? lead.motivo : null,
              recallHours: lead.recallHours ? lead.recallHours : null,
              recallDate: lead.recallDate ? lead.recallDate : null,
              lastModify: lead.lastModify ? lead.lastModify : null, 
              tipologiaCorso: lead.tipologiaCorso ? lead.tipologiaCorso : "",
              budget: lead.budget ? lead.budget : "",
              enrollmentTime: lead.enrollmentTime ? lead.enrollmentTime : "",
              oreStudio: lead.oreStudio ? lead.oreStudio : "",
              categories: lead.categories ? lead.categories : "",
              campagna: lead.utmCampaign ? lead.utmCampaign : "",
              leadAmbassador: lead.leadAmbassador ? lead.leadAmbassador : undefined,
              summary: lead.summary ? lead.summary : "",
              appDate: lead.appDate ? lead.appDate : "",
              provenienza: lead.campagna,
              priorità: lead.priorità ? lead.priorità : null,
              utmContent: lead.utmContent ? lead.utmContent : ""
            },
              start: dateTime,
              description: `${(lead.campagna === "AI chatbot" || (lead.appDate && lead.appDate?.trim()  !== '')) ? lead.appDate : dateTime}`,
            };
          });
          
          const mergedArray = filteredTableLead.concat(filteredDoppione);
          //console.log(mergedArray)
          const ori = localStorage.getItem("Ori");

          const filteredByRecall = mergedArray.filter((lead) => {
            return (lead.extendedProps.recallDate && lead.extendedProps.recallHours && lead.extendedProps.recallDate !== null) || (lead.extendedProps.appDate);
          });
    
          const filteredByOrientatore = filteredByRecall.filter((row) => {
            if (ori && ori !== null && ori !== undefined && orin.length > 0) {
              const selectedOrientatoreObj = orin.find(option => option._id === ori);
              const selectedOrientatoreFullName = selectedOrientatoreObj ? selectedOrientatoreObj.nome + ' ' + selectedOrientatoreObj.cognome : '';
              const rowOrientatoreFullName = row.extendedProps.orientatore;
              return rowOrientatoreFullName === selectedOrientatoreFullName;
            } else if (ori === "nonassegnato") {
              const rowOrientatoreFullName = row.extendedProps.orientatore;
              return rowOrientatoreFullName === "";
            } else {
              return true;
            }
          });

          setFilteredData(filteredByOrientatore);
          setOriginalData(filteredByRecall);
          //console.log(filteredByOrientatore);
          setIsLoading(false);
        } catch (error) {
          console.error(error);
        }
      };

      const getOrientatori = async () => {
        await axios.get(`/utenti/${state.user._id}/orientatori`)
          .then(response => {
            const data = response.data.orientatori;
  
            setOrientatoriOptions(data);
            fetchLeads(data);
          })
          .catch(error => {
            console.error(error);
          });
      }

      useEffect(() => {
        getOrientatori();
        const ori = localStorage.getItem("Ori");
        if (ori && ori !== null && ori !== undefined && ori !== "") {
          setSelectedOrientatore(ori);
        }
    
      }, []);

      const [isLoading, setIsLoading] = useState(true);
      const [openInfoCal, setOpenInfoCal] = useState(false);
      const [selectedLead, setSelectedLead] = useState(null);
      const [popupPosition, setPopupPosition] = useState({ top: 0, left: 0 });
      const [selectedOrientatore, setSelectedOrientatore] = useState("");
      const [openDeleteRecall, setOpenDeleteRecall] = useState(false);

      const saveNewRecall = async (leadId, recallDate, recallHours) => {
        //console.log(leadId, recallDate, recallHours)
        try {
          const response = await axios.post('/update-lead-recall', {
            leadId,
            recallDate,
            recallHours,
          });
      
          console.log('Lead aggiornata:', response.data);
          fetchLeads();
          toast.success('Recall Modificata!');

          const updatedLeads = [...filteredData]; // Crea una copia dell'array
          const leadIndex = updatedLeads.findIndex(lead => lead.id === leadId);
      
          if (leadIndex !== -1) {
            updatedLeads[leadIndex] = {
              ...updatedLeads[leadIndex],
              extendedProps: {
                ...updatedLeads[leadIndex].extendedProps,
                recallDate: response.data.recallDate,
                recallHours: response.data.recallHours,
              },
              start: moment(`${response.data.recallDate} ${response.data.recallHours}`, 'YYYY-MM-DD HH:mm:ss').toDate(),
            };
      
            setFilteredData(updatedLeads);
          } 
        } catch (error) {
          console.error('Errore durante l\'aggiornamento della lead:', error.message);
        }
      };

      const changeOrienta = (orientatore) => {
        setSelectedOrientatore(orientatore); 
        localStorage.setItem("Ori", orientatore);
        if (orientatore == ""){
          console.log('daje');
          setFilteredData(originalData);
        };
      }

      const handleUpdateLead = (updatedLead) => {
        setSelectedLead(updatedLead);
      };

      const deleteRecall = async () => {
        const recallDate = null;
        const recallHours = null;
        try {
          const response = await axios.post('/delete-recall', {
            leadId: selectedLead.id,
          });
      
          if (response.status === 200) {
            console.log('Recall eliminata con successo');
            toast.success('Recall eliminata');
            fetchLeads();
            setOpenDeleteRecall(false);
            setOpenInfoCal(false);
            const updatedLeads = [...filteredData];
            const leadIndex = updatedLeads.findIndex(lead => lead.id === selectedLead.id);
      
            if (leadIndex !== -1) {
              updatedLeads = updatedLeads.filter((lead, index) => index !== leadIndex);
              setFilteredData(updatedLeads);
            }
        }
          else {
            console.error('Errore durante l\'eliminazione della recall');
          }
        } catch (error) {
          console.error('Errore durante la richiesta:', error.message);
        }
      };

    return (
    <>
          {openInfoCal && selectedLead &&
          <div className="shadow-popup-modify">
            <PopupModifyCalendar
              onClose={() => {setOpenInfoCal(false); setSelectedLead(null)}}
              lead={selectedLead}
              onUpdateLead={handleUpdateLead}
              setPopupModify={() => {setOpenInfoCal(false); setSelectedLead(null)}}
              //popupRef={popupRef}
              fetchLeads={() => fetchLeads(orientatoriOptions)}
            />
            </div>
          }
        {openDeleteRecall && selectedLead && (
          <div className='delete-recall-popup popup-orientatore'>
            <h4>Vuoi cancellare l’appuntamento?</h4>
            <div>
              <button onClick={() => setOpenDeleteRecall(false)}>No</button>
              <button onClick={deleteRecall}>Si</button>
            </div>
          </div>
        )}
        {isLoading ? (
          <div></div>
        ): (
            <div>
                   <div className="topDash dashhideexport"
                    style={{gap: '13rem', fontSize: '0.8rem', margin: '30px 0'}}
                    >
                      <div className="topDash-item" id='fstdashitem'>
                          <label className="hideexport" style={{display: 'flex', alignItems: 'center', gap: '1rem', padding: "6px 12px", backgroundColor: '#fff', borderRadius: 15, width: '250px' }}>
                            <SearchOutlined color="white" id='looptopdash' />
                            <input
                              id='dashcerca'
                              type="text"
                              placeholder="Cerca.."
                              style={{ border: 'none', outline: 'none' }}
                              //onChange={(e) => SETsearch ? SETsearch(e.target.value) : {}}
                            />
                          </label>
                          {(!state.user.role || state.user.role !== "orientatore") && <select 
                          className='select-calendar'
                          value={selectedOrientatore}
                          onChange={(e) => changeOrienta(e.target.value)}
                          >
                            <option value="">Nessun filtro</option>
                            {orientatoriOptions.map((orientatore) => (
                              <option key={orientatore._id} value={orientatore._id}>
                                {orientatore.nome} {orientatore.cognome}
                              </option>
                            ))}
                          </select>}
                      </div>

                      <div className="topDash-item" id='lasttopdashitem'>
                          <div id='fstdiv'>
                            <span className='iniziale-top-dash'>{(!state.user.role || state.user.role !== "orientatore") ? state.user.name.charAt(0) : state.user.nome.charAt(0)}</span>
                            <p>ciao <span><u>{(!state.user.role || state.user.role !== "orientatore") ? state.user.name : state.user.nome}</u></span></p>
                          </div>
                      </div>
                    </div>
                <div className='calendar-container'>
                {filteredData && filteredData.length > 0 ? (
                  <MyCalendar 
                  saveNewRecall={saveNewRecall} 
                  setPopupPosition={setPopupPosition} 
                  setOpenInfoCal={setOpenInfoCal} 
                  setSelectedLead={setSelectedLead} 
                  setOpenDeleteRecall={setOpenDeleteRecall}
                  leads={
                    filteredData.filter((row) => {
                      if (selectedOrientatore !== "" && selectedOrientatore !== undefined && selectedOrientatore !== null) {
                        const selectedOrientatoreObj = orientatoriOptions.find(option => option._id === selectedOrientatore);
                        const selectedOrientatoreFullName = selectedOrientatoreObj ? selectedOrientatoreObj.nome + ' ' + selectedOrientatoreObj.cognome : '';
                        const rowOrientatoreFullName = row.extendedProps.orientatore;
                        return rowOrientatoreFullName === selectedOrientatoreFullName;
                      } else if (selectedOrientatore === "nonassegnato") {
                        const rowOrientatoreFullName = row.extendedProps.orientatore;
                        return rowOrientatoreFullName === "";
                      } else {
                        return true;
                      }
                    })
                  } />
                ) : (
                  <MyCalendar saveNewRecall={saveNewRecall} setPopupPosition={setPopupPosition} setOpenInfoCal={setOpenInfoCal} setSelectedLead={setSelectedLead} leads={filteredData && filteredData} />
                )}
                </div>
            </div>  
        )}
         
    </>
  )
}

export default CalendarM